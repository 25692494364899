import type { MailboxInfo } from 'owa-client-types';
import type { OpenLPCEvent, OpenLPCEventData } from './OpenLPCEvent';
import { OpenLPCEventType } from './OpenLPCEvent';
import type {
    OpenLivePersonaEditorEvent,
    OpenLivePersonaEditorData,
    PersonaIdentifiers,
} from './OpenLivePersonaEditor';
import { OpenLivePersonaEditorEventType } from './OpenLivePersonaEditor';
import { AppHostServiceEventOriginator } from './originator';
import {
    ComposeEmailEventType,
    type ComposeEmailEvent,
    type ComposeEmailEventData,
} from './ComposeEmailEvent';
import {
    StartCallViaSipProtocolEventType,
    type StartCallViaSipProtocolEvent,
    type StartCallViaSipProtocolEventData,
} from './StartCallViaSipProtocolEvent';
import {
    StartChatViaSipProtocolEventType,
    type StartChatViaSipProtocolEvent,
    type StartChatViaSipProtocolEventData,
} from './StartChatViaSipProtocolEvent';
import {
    ImportContactsEventType,
    type ImportContactsEvent,
    type ImportContactsEventData,
} from './ImportContactsEvent';
import {
    ExportContactsEventType,
    type ExportContactsEvent,
    type ExportContactsEventData,
} from './ExportContactsEvent';
import {
    initializeLPCEventType,
    type InitializeLPCEvent,
    type InitializeLPCEventData,
} from './InitializeLPCEvent';
import type {
    HostAppContext,
    OpenPersonSourcesData,
    OpenPersonSourcesEvent,
    PersonSourcesPersonaIdentifiers,
} from './OpenPersonSources';
import { OpenPersonSourcesEventType } from './OpenPersonSources';

import { ShowToastEventType, type ShowToastEvent, type ShowToastEventData } from './ShowToastEvent';
import {
    RefreshPersonInfoEventType,
    type RefreshPersonInfoEvent,
    type RefreshPersonInfoEventData,
} from './RefreshPersonInfoEvent';
import {
    StartChatViaImAddressUrlEventType,
    type StartChatViaImAddressUrlEvent,
    type StartChatViaImAddressUrlEventData,
} from './StartChatViaImAddressUrlEvent';
import {
    StartCallViaPhoneUrlEventType,
    type StartCallViaPhoneUrlEvent,
    type StartCallViaPhoneUrlEventData,
} from './StartCallViaPhoneUrlEvent';

export type AppHostServiceEvent =
    | OpenLPCEvent
    | OpenLivePersonaEditorEvent
    | OpenPersonSourcesEvent;

export const AppHostServiceEventTypes = {
    openLPC: OpenLPCEventType,
    initializeLPC: initializeLPCEventType,
    openLivePersonaEditor: OpenLivePersonaEditorEventType,
    openPersonSources: OpenPersonSourcesEventType,
    composeEmail: ComposeEmailEventType,
    startCallViaSipProtocol: StartCallViaSipProtocolEventType,
    startChatViaSipProtocol: StartChatViaSipProtocolEventType,
    importContacts: ImportContactsEventType,
    exportContacts: ExportContactsEventType,
    startChatViaImAddressUrl: StartChatViaImAddressUrlEventType,
    startCallViaPhoneUrl: StartCallViaPhoneUrlEventType,
    showToast: ShowToastEventType,
} as const;

export const AppHostServiceEvents = {
    initializeLPC: (data: () => InitializeLPCEventData): InitializeLPCEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.initializeLPC,
        data,
    }),
    openLPC: (data: () => OpenLPCEventData): OpenLPCEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.openLPC,
        data,
    }),
    openLivePersonaEditor: (
        mailboxInfo: MailboxInfo,
        personaIdentifiers?: PersonaIdentifiers,
        contactPrompt?: string
    ): OpenLivePersonaEditorEvent => ({
        originator: AppHostServiceEventOriginator,
        type: OpenLivePersonaEditorEventType,
        data: (): OpenLivePersonaEditorData => ({
            personaIdentifiers,
            mailboxInfo,
            contactPrompt,
        }),
    }),
    openPersonSources: (
        mailboxInfo: MailboxInfo,
        hostAppContext: HostAppContext,
        personaIdentifiers?: PersonSourcesPersonaIdentifiers
    ): OpenPersonSourcesEvent => ({
        originator: AppHostServiceEventOriginator,
        type: OpenPersonSourcesEventType,
        data: (): OpenPersonSourcesData => ({
            personaIdentifiers,
            mailboxInfo,
            hostAppContext,
        }),
    }),
    composeEmail: (data: () => ComposeEmailEventData): ComposeEmailEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.composeEmail,
        data,
    }),
    startCallViaSip: (
        data: () => StartCallViaSipProtocolEventData
    ): StartCallViaSipProtocolEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.startCallViaSipProtocol,
        data,
    }),
    startChatViaSip: (
        data: () => StartChatViaSipProtocolEventData
    ): StartChatViaSipProtocolEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.startChatViaSipProtocol,
        data,
    }),
    startCallViaPhoneUrl: (data: StartCallViaPhoneUrlEventData): StartCallViaPhoneUrlEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.startCallViaPhoneUrl,
        data: (): StartCallViaPhoneUrlEventData => data,
    }),
    startChatViaImAddressUrl: (
        data: StartChatViaImAddressUrlEventData
    ): StartChatViaImAddressUrlEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.startChatViaImAddressUrl,
        data: (): StartChatViaImAddressUrlEventData => data,
    }),
    importContacts: (data: () => ImportContactsEventData): ImportContactsEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.importContacts,
        data,
    }),
    exportContacts: (data: () => ExportContactsEventData): ExportContactsEvent => ({
        originator: AppHostServiceEventOriginator,
        type: AppHostServiceEventTypes.exportContacts,
        data,
    }),
    showToast: (data: ShowToastEventData): ShowToastEvent => ({
        originator: AppHostServiceEventOriginator,
        type: ShowToastEventType,
        data: (): ShowToastEventData => data,
    }),
    refreshPersonInfo: (data: RefreshPersonInfoEventData): RefreshPersonInfoEvent => ({
        originator: AppHostServiceEventOriginator,
        type: RefreshPersonInfoEventType,
        data: (): RefreshPersonInfoEventData => data,
    }),
} as const;
