import type { ApplicationSettings } from './schema/ApplicationSettings';

/**
 * All application settings should be defined here following the ECS configuration structure.
 * Please insert new settings in alphabetical order, keep a blank line between different settings to preserve readability and reduce chance of merge conflict.
 */
export const defaultApplicationSettings: ApplicationSettings = {
    AboutOutlook: { enabled: false },

    Addin: {
        devicePermissionAllowList: '',
        isVerboseLogsEnabled: false,
        disableUlsLogging: false,
    },

    Ads: {
        astJsURL: 'https://acdn.adnxs.com/ast/static/0.51.0/ast.js',
    },

    Analytics: {
        disabledDatapoints: [] as string[],
        throttledDatapoints: [] as string[],
        disableIsCoreDatapoints: [] as string[],
        enableHighCountEventThrottle: false,
        highCountEventThrottleWindow: 15,
        highCountEventThrottleThreshold: 250,
        capturePerfProfiles: [] as {
            name: string;
            expire: number;
        }[],
    },

    AppHealth: {
        appHealthIntervalInMs: 30 /*minutes*/ * 60 /*seconds*/ * 1000 /*milliseconds*/,
    },

    BingNativeAds: {
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementOther1: 'ARCZfUlvuZ0j9rVyyrDOVrqoWLU1DW5apsssi95fdg/SB0aIpD6l006AvcP/wXoOI40=', // 11706810
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementOther2: 'ARBR/vpvU+rZ5Amq3JsP/KgfscqG1qZwde2WRFRcGO+P5RfrTrEp2LPDb/01YVw4sF0=', // 11724134
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementPrimary: 'ARB68RharLDirX5n8PKMAX/gwjPisZPgkOV9uea6yzb7MptvVIFyqLkH0kWrQBTCff8=', // 11725881
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Bing API uses fraud detection to prevent unauthorized use.")]
        placementHeader: 'ARCyZ6WH59gW6TqsH4dLtbi0U2JLZrEm/ZO3JATMCanRMASaB4TfJmHWe09qcD2AS0g=', // 11725880
    },

    CAPIv3: {
        isCAPIv3Enabled: false,
    },

    CalendarMacFonts: {
        enabled: false,
    },

    CalendarMeetingApps: {
        meetingPlatform: 'Teams',
    },

    CalendarReplyToAttendees: {
        enabled: true,
    },

    CalendarRibbonNewEventButton: {
        isDropdownEnabled: true,
    },

    CalendarSchedulingPoll: {
        enabled: true,
    },

    CalendarSync: {
        stalenessThresholdInSeconds: 60,
    },

    CalendarTeamsToggle: {
        enabled: false,
        isFeedbackEnabled: false,
    },

    Copilot: {
        elaborateEnabled: false,
        coachEnabled: false,
        suggestedDraftsEnabled: false,
        summarizeEnabled: false,
        explainPlacesReportEnabled: false,
        replyByMeetingEnabled: false,
        meetingPrepSummaryEnabled: false,
        disableEdgeCopilotExtract: false,
        disableInboxShopping: false,
        disableAddins: false,
        enableMultiLanguage: false,
        calendarElaborateEnabled: false,
        calendarRewriteEnabled: false,
        timeCopilotChatEnabled: false,
    },

    CopilotAIX00: {
        prioritizeEnabled: false,
    },

    CopliotRing: {
        ringName: 'Dogfood',
    },

    EnabledResolvers: {
        Query: {},
        Mutation: {},
        Subscription: {},
    },

    ForwardingWithPopImapOption: {
        enabled: false,
    },

    GdprAds: {
        vendorListCdnUrl: 'https://res.cdn.office.net/assets/ads/7dttl/vendor-list.json',
        vendorListCdnV3Url: 'https://res.cdn.office.net/assets/ads/7dttl/vendor-list-v3.json',
    },

    Graph: {
        resourceUrl: 'https://graph.microsoft.com',
    },

    InboxShopping: {
        allowedDomainsURL: 'https:{0}assets/inboxshopping/v1/allowedDomainsBloom.json',
    },

    Loki: {
        resourceUrl: '',
        complianceEnvironment: '',
    },

    LoopAudience: {
        audience: '',
    },

    MonarchNotifications: {
        enabled: false,
    },

    MonarchSettingsColumnsFlyout: {
        enabled: false,
    },

    MSALUrl: {
        resourceBlockList: [
            'https://outlook-sdf.live.com',
            'https://outlook.live.com',
            'https://cortana.ai',
            'https://dataservice.o365filtering.com',
            'https://outlookimageproxy.azurewebsites.net',
        ],
        outlookgatewayUrl: 'outlookgatewayb2/.*/graphql',
        notificationChannelUrl: '.*/notificationchannel',
    },

    NativeAdIFrame: {
        iframeUrl: 'https://outlookads.live.com/mail/adsframe',
    },

    NativeReactions: {
        deviceType: 'Owa',
    },

    NewOutlookPromoDialog: {
        storeUrl:
            'ms-windows-store://pdp/?ProductId=9NRX63209R7B&mode=mini&cid=owaPromotionDownload',
    },

    Nps: {
        npsSamplingOwaAppRate: 1,
        npsSamplingOwaSuiteRate: 0.1,
        npsSamplingMonarchAppRate: 4,
        npsSamplingMonarchSuiteRate: 1,
    },

    OutlookServiceUrl: {
        outlookServiceDomain: '',
    },

    OneDriveReferral: {
        disableNudgeMessageInAttachmentWell: false,
    },

    PersonaOffline: {
        resyncIntervalMS: 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000 /*milliseconds*/,
        syncPageSize: 1000,
        maxCountToSync: 30000, // TODO @kamcheung -same setting as Teams. Honour when https://msfast.visualstudio.com/FAST/_workitems/edit/560707 is implemented
    },

    ReliabilityCheck: {
        isPresent: false,
    },

    Safelinks: {
        staticPageBaseUrl: 'https://outlook.office.com/mail/safelink.html',
        getServiceLocatorHost: 'https://na01.safelinks.protection.outlook.com',
        isSupported: true,
    },

    SettingsUxOutboundLinks: {
        showLinkPreviewLinks: true,
        showMobileGetAppLink: true,
        showPopImapSupportLink: true,
        showRulesInfoLink: true,
        showSmimeChromeExtensionLinks: true,
    },

    Chapter3AllowList: {
        registrationIds: [],
        sourceContents: [],
    },

    Chapter3BlockedList: {
        registrationIds: [],
        sourceContents: [],
    },

    CLPAudit: {
        resourceUrl: 'https://graph.microsoft.com',
    },

    GWSApi: {
        resourceUrl: 'https://graph.microsoft.com',
    },

    SuggestedAttachmentsRecommendationServiceCall: {
        ScenarioName: 'OutlookWebMonarch',
    },

    SuggestedAttachmentsSuppression: {
        enabled: false,
    },

    TaboolaNativeAds: {
        //[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="API key that can be snooped fron network traffic. Taboola API uses fraud detection to prevent unauthorized use.")]
        apiKey: '5b8cb6ece5c7b124b55d084f3a6ee7520d2f0966',
        publishIdFormat: 'msn-outlookcom-{0}',
        placementOther1: 'Inbox_Text_Widget',
        placementOther2: 'Inbox_Text_Widget_v2',
        placementPrimary: 'Inbox_Text_Primary',
        placementHeader: 'Nav_text',
        placementNonInbox: 'PCWeb_NonInbox',
        placementFloatingInbox: 'PCWeb_Float_Primary',
        placementFloatingOther: 'PCWeb_Float_Other',
    },

    TeamsAuth: {
        serviceName: 'Teams Auth Service',
        serviceBaseUrl: 'https://teams.microsoft.com/api/authsvc',
        serviceResourceUrl: 'https://api.spaces.skype.com',
    },

    TeamsMiddleTier: {
        serviceName: 'Teams Middle-Tier',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://api.spaces.skype.com',
    },

    TeamsMiddleTierS2S: {
        serviceName: 'Teams Middle-Tier S2S',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://api.spaces.skype.com',
    },

    TeamsArtifactsService: {
        serviceName: 'Teams Artifacts',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://teams.cloud.microsoft/6bc3b958-689b-49f5-9006-36d165f30e00',
    },

    TeamsVirtualEvents: {
        serviceName: 'Teams Virtual Events',
        serviceBaseUrl: '',
        serviceResourceUrl: 'https://events.teams.cloud.microsoft',
    },

    MeetingTemplates: {
        isTeamsHub: false,
    },

    TeamsURL: {
        resourceURL: '',
    },

    TrouterConfig: {
        TrouterServiceUrl: 'https://go.trouter.teams.microsoft.com/',
        RegistrarServiceUrl: 'https://teams.microsoft.com/registrar/prod/',
    },

    ToDo: {
        isToDoFeaturesEnabled: true,
    },

    UniversalMeControl: {
        enabled: true,
    },

    XandrNativeAds: {
        placementOther1: 'WAN1USEN',
        placementOther2: 'WAN2GBL',
        placementPrimary: 'PWP1GBL',
        placementHeader: 'PWN1GBL',
        placementNonInbox: 'PCWeb_NonInbox',
        placementFloatingInbox: 'PCWeb_Float_Primary',
        placementFloatingOther: 'PCWeb_Float_Other',
        placementOther12ndCall: 'SECWAN1USEN',
        placementOther22ndCall: 'SECWAN2GBL',
        placementPrimary2ndCall: 'SECPWP1GBL',
        placementHeader2ndCall: 'SECPWN1GBL',
        placementNonInbox2ndCall: 'SECPCWeb_NonInbox',
        placementFloatingInbox2ndCall: 'SECPCWeb_Float_Primary',
        placementFloatingOther2ndCall: 'SECPCWeb_Float_Other',
        placementFocusInbox: 'PCWeb_FocusedInbox',
        placementFocusInbox2ndCall: 'SECPCWeb_FocusedInbox',
    },

    YahooAds: {
        jacJsURL: 'https://jac.yahoosandbox.com/0.14.0-msft/jac.js',
    },

    Diagnostics: {
        panel: false,
    },

    PoisonedBuild: {
        skipBuilds: [],
    },

    ActionableMessage: {
        resourceUrl: 'https://outlook.office365.com/connectors',
    },

    MsPlaces: {
        insightsWithMockData: [],
        enableInsightsTab: false,
        enableBuildingAnalyticsTab: true,
        spaceManagementTabEnabled: false,
        enableDeskpoolTab: true,
        enableAdminAvailableOptions: true,
        enableAnalyticsLearning: true,
        enableHelpVideos: true,
        enableAnalyticsMessageBar: true,
        placesAnalyticsCentroBaseUrl: 'https://admin-ignite.microsoft.com', // This is the default value for SDF & Local environment
    },

    MessageRecall: {
        resourceUrl: 'https://outlook.office.com/messagerecallnetcore/',
    },

    ForceReboot: {
        NativeForceRebootEnabled: false,
        WebForceRebootEnabled: false,
    },

    DistributionGroups: {
        exchangePortalUrl: 'https://go.microsoft.com/fwlink/?linkid=2236662',
    },

    AddisonOutage: {
        hasOutage: false,
        outageMessage: '',
        outageLinkUri: '',
    },

    MonarchLegacyToggle: {
        delayInSecondsBeforeRenderingFeedback: 2,
    },

    EventifyOutage: {
        hasOutage: false,
        outageLinkUri: '',
    },

    Dictation: {
        shouldAcquireMicViaNavigator: false,
    },

    PlatformType: {
        isMonarch: false,
    },

    RecapDeeplink: {
        phase0Enabled: false,
    },

    PreAuthShellResourceUrls: {
        webShellResourceUrl: 'https://webshell.suite.office.com',
        graphResourceUrl: 'https://graph.microsoft.com',
        officeApiResourceUrl: 'https://api.office.net',
    },

    PlacesOutage: {
        hasOutage: false,
        outageLinkUri: '',
        outageEnumIdMessage: 0,
        outageCustomMessage: '',
        typeMessageVariant: 0,
    },

    Topics: {
        loadSDK: true,
    },

    SydneyEndpoint: {
        cafe: 'https://substrate.office.com/m365chat/Chathub',
        cortana: 'https://api.cortana.ai/m365chat/SecuredChatHub',
        avalon: 'https://substrate.office.com/m365Copilot/Chathub',
        // avalon sdf-pilot: 'https://sdf-s01-00-nam-westus.substrate.cosmic-ppe.office.net/m365Copilotcanary/Chathub'
        // avalon sdf: 'https://substrate.office.com/m365Copilot/Chathub'
    },

    MeetingPrepSydney: {
        zeroQuery: '',
        variants: '',
        endpointInfra: 'avalon',
        isDebugEnabled: false,
        avalonVariants:
            'feature.includeExternal,feature.EnableAuthErrorMessage,feature.EnableRequestPlugins,EnableRequestPlugins,feature.EnableSensitivityLabels,EnableUnsupportedUrlDetector,MetricsSummaryEnabled,feature.EnableMessageExtensionAnnotations,3S.ProcessMECardTitleForEntityAnotation,feature.EnableRecommendedItems,feature.EnableMeetingPrepChatForAvalon,feature.IsPreviousRecordingPrepEnabled,feature.EnableLoopFilesForMeetingPrep,feature.EnableArtifactsInSydneyResponse',
        zeroQueryAvalon: '',
        optionSets: ['enterprise'],
        avalonOptionSets: [
            'enterprise_flux_work',
            'enable_converged_response_schema',
            'enable_request_response_interstitials',
            'enterprise_toolbox_with_skdsstore',
            'teams_action_message_extensions_enable',
            'external_message_extension_entity_additional_tag',
            'at_mention_plugins_enable',
            'teams_search_message_extensions_enable',
        ],
    },

    PlacesAnalyticsHelpVideosUrls: {
        ViewBuildingAnalyticsVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1lhw3?pid=ocpVideo1&maskLevel=20&market=en-us',
        SwitchLocationsVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1leYm?pid=ocpVideo3&maskLevel=20&market=en-us',
        ChangeDateRangeVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1lhw8?pid=ocpVideo2&maskLevel=20&market=en-us',
        ApplyFiltersVideoUrl: '',
        ConfigureWorkDaysVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1lhwt?pid=ocpVideo5&maskLevel=20&market=en-us',
        ExplainDataUsingCopilotVideoUrl: '',
        ShareReportsVideoUrl:
            'https://www.microsoft.com/en-us/videoplayer-nocookie/embed/RW1ljLf?pid=ocpVideo4&maskLevel=20&market=en-us',
    },

    RebootOnSettingsChange: { enabled: false },

    SwCache: {
        successRatio: 1,
        chunkSize: 100,
    },

    Upsell: {
        adsFreeUpsellUrl: 'https://go.microsoft.com/fwlink/?linkid=2222261',
        adBlockerUpsellUrl: 'https://go.microsoft.com/fwlink/?linkid=2222646',
    },

    Urlp: {
        resourceUrl: 'https://urlp.asm.skype.com',
        endpointUrl: 'https://urlp.asm.skype.com/v1/url/info?url=',
    },

    SafelinksURLConfigs: {
        SafelinksHostnames:
            'safelinks.protection.com,safelinks.protection.office365.us,safelinks.protection.outlook.com,safelinks.protection.outlook.cn,safelinks.protection.outlook.de,safelinks.o365filtering-int.com',
        SpoLofHostnames:
            'sharepoint-df.com,sharepoint.com,sharepoint.de,sharepoint.cn,sharepoint-mil.us,sharepoint.us',
        GetParams: 'url',
        DropParams: 'data,sdata,reserved',
        SpoLofDropParams: 'xsdata,sdata,ovuser,clickParams',
    },

    MeetNow: {
        shouldShowInHeader: true,
        useOwaMailHandler: false,
        useTeamsSDKForJoinMeeting: false,
    },

    IntelligentRecap: {
        shouldSwitchHivePayload: false,
        alwaysShowRecapChiclet: false,
    },

    PrepareModule: {
        enabled: false,
    },

    PrivacySettings: {
        enabled: false,
        roamingEnabled: false,
    },

    PDV: {
        peoOfflineTemplate: true,
        peoOfflineDisablePrefetch: true,
        peoOfflineLokiShadow: true,
        peoOfflineInMemorySort: true,
        peoOfflineIndexSort: true,
        peoSubstrateSearch: true,
        peoInvalidateLokiContactCache: true,
    },

    Jana: {
        peopleNovaPeopleApp: true,
        lpeNova: true,
        nsatFeedback: true,
    },

    OnePersonView: {
        enabled: false,
        enabledForPeopleHub: false,
    },

    TipsTour: {
        enabled: false,
    },

    NetCore: {
        enabledAPIs: [],
        disabledAPIs: [],
    },
};
