import { logGreyError } from 'owa-analytics';
import type CalendarEvent from 'owa-calendar-types/lib/types/CalendarEvent';
import type { ProviderPropertiesEntry } from 'owa-graph-schema';

/**
 * Gets coordinates for the meeting options for the given event from the provider properties returned by online meeting service.
 * When provider properties are not available, it falls back to getting coordinates from the online meeting join url.
 * @param event calendar event
 * @returns coordinates for the meeting options
 */
const getCoordinatesForMeetingOptions = (
    event: CalendarEvent | null
): URLSearchParams | undefined => {
    if (!event) {
        return undefined;
    }

    // Online meetings created using the new experience will have meeting options url as part of the response.
    const providerProperties = event.OnlineMeeting?.providerProperties as ProviderPropertiesEntry[];

    if (providerProperties?.length > 0) {
        const meetingOptionsLink = providerProperties?.find(
            property => property?.key === 'links.options'
        )?.value;

        if (meetingOptionsLink) {
            // Get the query string part of the meeting options url.
            const meetingCoordinates = new URL(meetingOptionsLink).searchParams;

            // Apply the retrieved coordinates to the new URLSearchParams object for consistency.
            const newCoordinates = new URLSearchParams();

            setParameters(
                newCoordinates,
                meetingCoordinates.get('tenantId') ?? '',
                meetingCoordinates.get('organizerId') ?? '',
                meetingCoordinates.get('threadId') ?? '',
                meetingCoordinates.get('messageId') ?? '',
                meetingCoordinates.get('additionalMessageId') ?? undefined,
                'providerProperties'
            );

            return newCoordinates;
        }
    }

    logGreyError(
        'getCoordinatesForMeetingOptions',
        new Error('Fetching meeting coordinates from OnlineMeetingJoinUrl fallback.')
    );

    // Fall back to the old way of getting coordinates from OnlineMeetingJoinUrl + SkypeTeamsProperties (only applicable in edit mode).
    return event.OnlineMeetingJoinUrl && event.SkypeTeamsProperties
        ? getCoordinatesFallback(event)
        : undefined;
};

/**
 * Get coordinates for the meeting options for events created before client started sending the value using pre-create.
 * This function converts something like this:
 * https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmFhNzEwZmEtNjNlYS00NTkwLThjZTgtMWE2MjBjMmQwNTg1%40thread.v2/0?context=%7b%22Tid%22%3a%2234e3e3b4-85f3-4e36-b494-53604cf41c0d%22%2c%22Oid%22%3a%22bd95b217-f088-4334-99fc-f52d98f928d6%22%7d
 * to something like this:
 * organizerId=bd95b217-f088-4334-99fc-f52d98f928d6&tenantId=34e3e3b4-85f3-4e36-b494-53604cf41c0d&threadId=19_meeting_NmFhNzEwZmEtNjNlYS00NTkwLThjZTgtMWE2MjBjMmQwNTg1@thread.v2&messageId=0
 * @param event calendar event
 * @returns coordinates for the meeting options
 */
const getCoordinatesFallback = (event: CalendarEvent): URLSearchParams => {
    const meetingCoordinates = new URLSearchParams();

    try {
        const onlineMeetingJoinUrl = new URL(event.OnlineMeetingJoinUrl);
        const joinUrlParameters = onlineMeetingJoinUrl.searchParams;
        const contextString = joinUrlParameters.get('context');
        const context = contextString ? JSON.parse(contextString) : null;

        const organizerId = context?.Oid;
        const tenantId = context?.Tid;

        if (!organizerId || !tenantId) {
            throw new Error(
                'Missing required context parameters organizerId/tenantId when fetching meeting coordinates using fallback.'
            );
        }

        const skypeTeamsProperties = event.SkypeTeamsProperties;
        const threadId = skypeTeamsProperties?.cid?.replace('19:', '19_'); // Replace 19: with 19_ to match the format of the threadId that the service can accept.
        if (!threadId) {
            throw new Error('Missing threadId when fetching meeting coordinates using fallback.');
        }

        const isReplyChainMeeting = !!(
            skypeTeamsProperties?.rid !== skypeTeamsProperties?.mid &&
            !!(skypeTeamsProperties?.rid && skypeTeamsProperties?.mid)
        );

        const rootMessageId = skypeTeamsProperties?.rid ?? 0;
        const additionalMessageId = skypeTeamsProperties?.mid ?? 0;

        setParameters(
            meetingCoordinates,
            tenantId,
            organizerId,
            threadId,
            rootMessageId.toString(),
            isReplyChainMeeting ? additionalMessageId.toString() : undefined,
            'fallbackProperties'
        );
    } catch (error) {
        logGreyError('getCoordinatesForMeetingOptions: fallback failed.', error);
    }

    return meetingCoordinates;
};

function setParameters(
    coordinates: URLSearchParams,
    tenantId: string,
    organizerId: string,
    threadId: string,
    messageId: string,
    additionalMessageId: string | undefined,
    source: string
): void {
    coordinates.set('tenantId', tenantId);
    coordinates.set('organizerId', organizerId);
    coordinates.set('threadId', threadId);
    coordinates.set('messageId', messageId);
    if (additionalMessageId) {
        coordinates.set('additionalMessageId', additionalMessageId);
    }
    coordinates.set('source', source);
}

export default getCoordinatesForMeetingOptions;
